var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sx-dialog",
    {
      ref: "confirmDialog",
      attrs: { title: _vm.title || "Aktion bestätigen" },
    },
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _c(
        "template",
        { slot: "actions" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { small: "", color: "primary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.onConfirm.apply(null, arguments)
                },
              },
            },
            [_vm._v("Bestätigen")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }