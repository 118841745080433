<template>
  <sx-dialog ref="confirmDialog" :title="title || 'Aktion bestätigen'">
    {{text}}
    <template slot="actions">
        <v-spacer/>
        <v-btn small color="primary" @click.native="onConfirm">Bestätigen</v-btn>
    </template>
  </sx-dialog>
</template>

<script>
export default {
    name: 'sx-confirm-dialog',
    data() {
        return {
            text: null,
            title: null,
            callback: null
        }
    },
    created() {
        this.$eventBus.$on('on-confirm', (title, text, callback) => {
            this.text = text
            this.title = title
            this.callback = callback
            this.$refs["confirmDialog"].show()
        })
    },
    methods: {
        onConfirm() {
            if(this.callback != null)
                this.callback()
            this.$refs["confirmDialog"].close()
        }
    }
}
</script>

<style>

</style>